import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { buildLoadConfigurationCategoriesUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { CATEGORY_TYPES, ConfigurationCategory } from '../types';

export const loadConfigurationCategories = (
    filters: FilterObject[],
    type: CATEGORY_TYPES,
): Observable<ConfigurationCategory[]> => {
    const convertedType = type === CATEGORY_TYPES.DISPATCH_HEADER ? CATEGORY_TYPES.HEADER : type;
    const obs = sendRetrieveRequest(buildLoadConfigurationCategoriesUrl(filters, convertedType));

    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const categories: ConfigurationCategory[] = (
                response[`${convertedType}Category`] as any[]
            ).map((x) => ({
                ...convertToCamel(x),
                categoryType: type,
            }));
            return observableOf(categories);
        }),
    );
};
